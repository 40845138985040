/**
 * This function is used both for the on-page (full-size) widget preview, and the smaller preview iframe. It accepts `eventData` provide by event.details of a CustomEvent, and sets the global widget config object (used for initialising/reinitialising) or the necessary CSS variables.
 * @param {object} eventData
 * @param {boolean} reInitOnUpdate
 */
export function widgetPreviewUpdateEventHandler(
    eventData,
    reInitOnUpdate = false
) {
    const widgetEl = document.querySelector(".gt-cookie-widget");
    const type = eventData.type;
    const value = eventData.value;

    if (!type || !value) {
        console.error("Missing type or value in eventData:", eventData);
        return;
    }

    console.log(type);
    console.log(value);

    switch (type) {
        case "updateTheme":
            document.body.style.setProperty(value.variable, value.value);
            break;
        case "updateAppearance":
            window.gtCookieWidgetConfig.config.layout.appearance = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updatePosition":
            window.gtCookieWidgetConfig.config.layout.position = value;
            if (reInitOnUpdate && widgetEl) {
                widgetEl.classList.remove(
                    "gt-cookie-widget--left",
                    "gt-cookie-widget--center",
                    "gt-cookie-widget--right"
                );
                widgetEl.classList.add("gt-cookie-widget--" + value);
            }
            break;
        case "updateCookiePolicyUrl":
            window.gtCookieWidgetConfig.config.content.cookie_policy_url = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateCookiePolicyLinkText":
            window.gtCookieWidgetConfig.config.content.cookie_policy_link_text = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateMessage":
            window.gtCookieWidgetConfig.config.content.widget_banner_message = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updatePillMessage":
            window.gtCookieWidgetConfig.config.content.widget_pill_message = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateWidgetManagePreferences":
            window.gtCookieWidgetConfig.config.content.widget_manage_preferences_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateWidgetRejectAllButton":
            window.gtCookieWidgetConfig.config.content.widget_reject_all_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateWidgetAcceptAllButton":
            window.gtCookieWidgetConfig.config.content.widget_accept_all_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateWidgetShowCookiesButton":
            window.gtCookieWidgetConfig.config.content.widget_show_cookies_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateWidgetHideCookiesButton":
            window.gtCookieWidgetConfig.config.content.widget_hide_cookies_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateDialogTitle":
            window.gtCookieWidgetConfig.config.content.dialog_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateDialogMessage":
            window.gtCookieWidgetConfig.config.content.dialog_message = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateAllowPreferences":
            window.gtCookieWidgetConfig.config.functionality.allow_preferences = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateShowPreferencesAfterClose":
            window.gtCookieWidgetConfig.config.functionality.allow_update_after_close = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateBlockInteraction":
            window.gtCookieWidgetConfig.config.functionality.block_interaction = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateRejectAll":
            window.gtCookieWidgetConfig.config.functionality.has_reject_all = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateDialogSavePreferences":
            window.gtCookieWidgetConfig.config.content.dialog_save_preferences_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateDialogAcceptAll":
            window.gtCookieWidgetConfig.config.content.dialog_accept_all_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateDialogRejectAll":
            window.gtCookieWidgetConfig.config.content.dialog_reject_all_button = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateEssentialTitle":
            window.gtCookieWidgetConfig.config.content.cookie_essential_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateEssentialDescription":
            window.gtCookieWidgetConfig.config.content.cookie_essential_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateFunctionalTitle":
            window.gtCookieWidgetConfig.config.content.cookie_functional_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateFunctionalDescription":
            window.gtCookieWidgetConfig.config.content.cookie_functional_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateMarketingTitle":
            window.gtCookieWidgetConfig.config.content.cookie_marketing_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateMarketingDescription":
            window.gtCookieWidgetConfig.config.content.cookie_marketing_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateAnalyticsTitle":
            window.gtCookieWidgetConfig.config.content.cookie_analytics_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateAnalyticsDescription":
            window.gtCookieWidgetConfig.config.content.cookie_analytics_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateUnclassifiedTitle":
            window.gtCookieWidgetConfig.config.content.cookie_unclassified_title = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateUnclassifiedDescription":
            window.gtCookieWidgetConfig.config.content.cookie_unclassified_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateCookieHeadingPlatform":
            window.gtCookieWidgetConfig.config.content.cookie_heading_platform = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateCookieHeadingNameDescription":
            window.gtCookieWidgetConfig.config.content.cookie_heading_name_description = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
        case "updateCookieHeadingRetention":
            window.gtCookieWidgetConfig.config.content.cookie_heading_retention = value;
            if (reInitOnUpdate) {
                window.gtCookieWidgetPreview();
            }
            break;
    }
}
